const images = ({ limit = 1, label, type = "image", required = true }: { limit?: number, label?: string, type?: string, required?: boolean }) => ({
    type: "array",
    rules: {
        required
    },
    value: [],
    ui: {
        widget: "upload",
        label,
        widgetConfig: {
            uploadUrl: "/upload",
            resField: "data",
            fileUrlField: "url",
            autoUpload: true,
            showFileList: true,
            listType: type === "image" ? (limit === 1 ? "picture-card" : "picture") : "text",
            limit,
            multiple: true
        },
    },
})



export default images






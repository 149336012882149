
import images from "./widget/images"
export default
    {
        "type": "object",
        "properties": {
            "title": {
                "type": "string",
                "rules": {
                    "required": true,
                },
                "ui": {
                    "label": "title"
                }
            },
            "subtitle": {
                "type": "string",
                "rules": {
                    "required": true,
                },
                "ui": {
                    "label": "subtitle"
                }
            },
            "content": {
                "type": "string",
                "ui": {
                    "label": "content",
                    "widget": "editor"
                }
            },

            "imageOnBanner": images({ label: "banner上小图" }),
            "thumb": images({}),
            "icon": images({}),
            "jicon": images({ label: "跳转 icon" }),
            "width": {
                "type": "string",
                "ui": {
                    "label": "图标宽度",
                }
            },

            "left": {
                "type": "string",
                "ui": {
                    "label": "相对区块左边坐标",
                }
            },
            "top": {
                "type": "string",
                "ui": {
                    "label": "相对区块顶边坐标",
                }
            },


        }
    }